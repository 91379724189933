<template>
  <instance-card
      v-if="showInstanceDetail"
      :entity="entityTitle"
      :item="instanceObject"
      :schema="entityFields"
      :cardType="instanceCardType"
      :isModal="isModal"
  >
  </instance-card>
</template>
<script>
import InstanceCard from "./InstanceCard.vue";
import EntityApi from "@/api/entityApi";
import {hasProperty} from "@/helpers";

export default {
  name: "EntityDetails",
  components: {
    InstanceCard
  },
  data() {
    return {
      isModal: false,
      rowData: null,
      column: [],
      entityTitle: null,
      showInstanceDetail: false,
      instanceObject: null,
      entityFields: null,
      instanceCardType: "show", // show edit
      parrentEntityId: null

    };
  },
  watch: {},
  computed: {
    instanceDefaultValues() {
      return {
        [this.subtableSchema?.relationship_field]: this.parrentEntityId
      }
    }
  },
  methods: {
    async initEntityPage() {
      this.column = null;
      this.entityTitle = this.$route?.params?.entity;
      await this.getEntityFields(this.$route?.params?.entity);
      await this.getEntityItems(this.$route?.params?.entity, this.$route?.params?.id);
      if (this.instanceObject && this.entityFields) {
        this.showInstanceDetail = true;
      }
    },
    async getEntityFields(entity) {
      this.entityFields = await EntityApi.getEntityFields(entity);
      this.column = this.generateColumn(this.entityFields);
      this.separateString(this.entityFields)
    },
    async getEntityItems(entity, id) {
      this.instanceObject = await EntityApi.getEntityData(entity, id);
    },
    generateColumn(schema) {
      let column = [];
      let actionColl = {
        cellRenderer: "rowMenuCellRenderer",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerName: "",
        maxWidth: 65,
        minWidth: 65,
        width: 65,
        pinned: false,
        sortable: false,
        suppressMenu: false,
      };
      column.push(actionColl);
      if (schema?.list_columns && Array.isArray(schema.list_columns)) {
        schema.list_columns.forEach((el) => {
          if (el !== "id") {
            let columnItem = this.generateColumnItem(
                schema?.properties[el],
                el
            );
            if (columnItem) {
              column.push(columnItem);
            }
          }
        });
      }
      return column;
    },
    generateColumnItem(itemObg, item) {
      //console.log('generateColumnItem',itemObg, item);
      let newItem = {
        field: item,
      };
      if (hasProperty(itemObg, "title")) {
        newItem.headerName = itemObg.title;
      }
      if (hasProperty(itemObg, "$ref")) {
        newItem.cellRenderer = "objectRenderer";
        newItem.valueGetter = `data.${item}.name`;
        // newItem.keyCreator = params => params.value.name;
      }
      if (this.isFileField(item)) {
        newItem.cellRenderer = "fileRenderer";
      }
      if (hasProperty(itemObg, "format")) {
        switch (itemObg.format) {
          case "date-time":
            newItem.cellRenderer = "dateRenderer";
            break;
          default:
            break;
        }
      }
      if (hasProperty(itemObg, "type")) {
        switch (itemObg.type) {
          case "boolean":
            newItem.cellRenderer = "boolRenderer";
            break;
          default:
            break;
        }
      }
      return newItem;
    },
    isFileField(field) {
      return this.entityFields?.file_fields.includes(field) ? true : false;
    },
    separateString(value) {
      const words = this.entityFields.title.match(/([A-Z][A-Z]*(?=[A-Z][a-z])|[A-Z][a-z]+)/g);
      if (words) {
        const firstWord = words[0];
        const secondWord = words[1];
        this.entityFields.title = firstWord + " " + secondWord;
      }
      return value;
    }
  },
  mounted() {
    this.initEntityPage();
  },
  unmounted() {
  },
  beforeCreate() {
  },
  created() {

  },
};
</script>
<style scoped>
</style>
